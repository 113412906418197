import React, { Component } from "react";
import "antd/dist/antd.css";
import Login from "./Login";
import {
  Form,
  Input,
  Popover,
  Button,
  Row,
  Col,
  notification,
  message,
  Modal,
  Divider,
} from "antd";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "../config/AwsConfig";
import Advertisement from "../common/Advertisement";
import AppConfig from "../config/AppConfig";
import { withRouter, Link } from "react-router-dom";
import { getDetails, update } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import SignupModal from "../common/SignupModal";
// import logo from "../images/logo.png";
import logo from "../images/loginLogo.png";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import SigninWithFacebook from "./SigninWithFacebook";
import SigninWithGoogle from "./SigninWithGoogle";
Amplify.configure(AwsConfig);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      loading: false,
      email: "",
      showSignupPopup: false,
      openLoginPopup: true,
      openForgotPopup: false,
      openConfirmpasswordPopup: false,
      confirmationCode: "",
      passwordConfirm: false,
      validatePassword: "",
      validateConfirmPassword: "",
      newpassword: "",
      passwordError: "",
      loginEmail: "",
      loginPassword: "",
      visible: false,
    };
  }

  formRef = React.createRef();

  componentDidMount = () => {
    if (localStorage.getItem("currentPage")) {
      if (localStorage.getItem("currentPage") === "confirmPage") {
        this.setState({
          email: localStorage.getItem("forgotEmail"),
          openLoginPopup: false,
          openForgotPopup: false,
          openConfirmpasswordPopup: true,
        });
      } else if (localStorage.getItem("currentPage") === "forgotPage") {
        this.setState({
          openLoginPopup: false,
          openForgotPopup: true,
          openConfirmpasswordPopup: false,
        });
      }
    }
  };

  handleOnChange = (element, value, id) => {
    const valid =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
        value
      );
    if (valid) {
      this.setState({ [id]: true, [element]: value });
    } else if (value === "") {
      this.setState({ [id]: "" });
    } else {
      this.setState({ [id]: false });
    }
    if (element === "confirmnewpassword") {
      if (value === this.state.newpassword) {
        this.setState({ passwordConfirm: true });
      }
    }
  };

  onFinish = async (e) => {
    try {
      this.setState({
        loading: true,
      });
      let lowerEmail = e.email.toLowerCase();
      const user = await Auth.signIn(lowerEmail, e.password);
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      await this.setState({
        loading: false,
      });
      this.props.context.setUser(userDetails, false);
      this.props.context.handleToSetCurrentPage(1, 10);
      this.props.context.setUserLogin(true);
      localStorage.removeItem("selectedCities");
      const filterdata = [];
      this.props.context.setFilterData(filterdata);
      getDetails(
        AppConfig["entityNames"]["users"],
        userDetails.userid + "/?totalDetails=true"
      ).then((result) => {
        if (result.success) {
          this.userLoginCount(
            result.data.loginCount,
            userDetails.userid,
            result.data.totalVisits
          );
          this.props.context.setUserDetails(result.data);
          if (result?.data?.subscriptionPack !== undefined) {
            localStorage.setItem(
              "subscriptionPack",
              JSON.stringify(result.data.subscriptionPack)
            );
          }
          localStorage.setItem("userDetails", JSON.stringify(result.data));
          localStorage.setItem("userLogin", JSON.stringify(true));
          localStorage.removeItem("signupUserSelectedProfile");
          localStorage.removeItem("facebookAccessToken");
          localStorage.removeItem("fbUserProfilePic");
          localStorage.setItem(
            "userLoginTime",
            JSON.stringify(new Date().getTime())
          );
          if (
            result.data.userProfile === "B" &&
            ((result.data.newdata && !result.data.newdata.serviceAreas) ||
              (result.data.newdata && !result.data.buyerHomeFeatures) ||
              (result.data.newdata && !result.data.buyerHomeFeatures) ||
              (result.data.newdata &&
                result.data.newdata.serviceAreas &&
                result.data.newdata.serviceAreas.length === 0) ||
              (result.data.newdata &&
                result.data.buyerHomeFeatures &&
                result.data.buyerHomeFeatures.minPrice === "") ||
              (result.data.newdata &&
                result.data.buyerHomeFeatures &&
                result.data.buyerHomeFeatures.maxPrice === ""))
          ) {
            if (this.props.goTopreviousPage) {
              window.location.reload();
            } else {
              this.props.history.push(`/viewProfile/${result.data.id}`);
            }
          } else {
            if (this.props.goTopreviousPage) {
              window.location.reload();
            } else {
              if (
                result.data.userProfile === "B" ||
                result.data.userProfile === "S"
              ) {
                this.props.history.push(`/viewProfile/${result.data.id}`);
              } else {
                this.props.history.push("/homes");
              }
            }
          }
        } else {
          console.log(result.errors);
        }
      });
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        this.setState({
          loginEmail: e.email.toLowerCase(),
          loginPassword: e.password,
          visible: true,
          errors: err.message,
          loading: false,
        });
      } else if (err.code === "PasswordResetRequiredException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else if (err.code === "NotAuthorizedException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
        notification["error"]({
          message: "Wrong email or password. Please try again.",
        });
      } else if (err.code === "UserNotFoundException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
        notification["error"]({
          message: "Wrong email or password. Please try again.",
        });
      } else {
        this.setState({
          loading: false,
          errors: err.message,
        });
      }
      this.formRef.current.resetFields();
    }
  };

  userLoginCount = async (loginCount, id, totalResults) => {
    let todayVists = {};
    let totalVisits = totalResults ? totalResults : [];
    let currentDate = moment(new Date()).format("MM/DD/YYYY");
    let oldDate = totalResults?.filter(
      (eachDate) => eachDate.check_today_date === currentDate
    );
    if (oldDate && oldDate.length) {
      oldDate[0]["visited"] = oldDate[0]["visited"] + 1;
      let replaceIndex = totalVisits.indexOf(oldDate[0]["check_today_date"]);
      totalVisits.splice(replaceIndex, 1, oldDate[0]);

      totalVisits.filter((item, index, arr) => arr.indexOf(item) === index);
    } else {
      todayVists = {
        check_today_date: currentDate,
        visited: 1,
      };
      totalVisits.push(todayVists);
    }
    const data = Object.assign(
      {},
      {
        entityData: {
          loginCount: loginCount && loginCount > 0 ? loginCount + 1 : 1,
          totalVisits,
        },
      }
    );
    await update("users", id, data);
  };

  handleEmailForm = (e) => {
    this.setState({
      email: e.target.value.toLowerCase(),
    });
  };

  content = (
    <div>
      <p>-Minimum password length: 6</p>
      <p>-Required at least one uppercase letter</p>
      <p>-Required at least one lowercase letter</p>
      <p>-Required at least one number</p>
      <p>-Required at least one nonalphanumeric character</p>
    </div>
  );

  handleSignupPopup = () => {
    if (window.location.pathname === "/login") {
      this.props.history.push("/register");
    } else {
      if (this.props.showLoginSiderForm && this.props.questionPage) {
        this.props.handleToshowLoginSider();
      } else {
        this.setState({
          showSignupPopup: true,
        });
        this.props.closePreviouspopUp();
      }
    }
  };

  handleForgotSubmit = (e) => {
    this.setState({
      loading: true,
    });
    let email = e.email;

    if (!email.includes("@") || !email.includes(".com")) {
      notification["error"]({
        message: "Please enter valid email.",
      });
      this.setState({
        loading: false,
      });
    } else {
      Auth.forgotPassword(email)
        .then((data) => {
          this.setState({
            email,
            openForgotPopup: false,
            openConfirmpasswordPopup: true,
            loading: false,
            passwordError: "",
          });
          localStorage.setItem("currentPage", "confirmPage");
          localStorage.setItem("forgotEmail", email);
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            passwordError: err.message,
          });
          if (err.code === "UserNotFoundException") {
            notification["error"]({
              message: "This email address is not registered in our system.",
            });
          }
        });
    }
  };

  handleConfirmPasswordSubmit = (e) => {
    let email = this.state.email;
    let confirmationCode = e.confirmationCode;
    let password = e.newpassword;
    let confirmPassword = e.confirmnewpassword;
    if (password !== confirmPassword) {
      this.setState({
        passwordError: "Both passwords should match. Please check your entry.",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    Auth.forgotPasswordSubmit(email, confirmationCode, password)
      .then((data) => {
        notification["success"]({
          message: "Password Changed",
          description: "your password has been changed successfully.",
        });
        this.setState({
          email: "",
          openLoginPopup: true,
          openForgotPopup: false,
          openConfirmpasswordPopup: false,
          loading: false,
          passwordError: "",
        });
        localStorage.removeItem("forgotEmail");
        localStorage.removeItem("currentPage");
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          passwordError: err.message,
        });
      });
  };

  handleonCancel = () => {
    this.setState({
      showSignupPopup: false,
    });
  };

  handleOk = async (e) => {
    this.setState({
      loading: true,
    });
    const { loginEmail, loginPassword } = this.state;
    try {
      if (e.code !== "") {
        await Auth.confirmSignUp(loginEmail, e.code).then(async (result) => {
          const user = await Auth.signIn(loginEmail, loginPassword);
          const userDetails = Object.assign(
            {},
            {
              token: user.signInUserSession.idToken.jwtToken,
              userid: user.username,
            }
          );
          this.setState({
            loading: false,
          });
          await this.setState({
            loading: false,
          });
          this.props.context.setUser(userDetails, false);
          this.props.context.handleToSetCurrentPage(1, 10);
          this.props.context.setUserLogin(true);
          localStorage.removeItem("selectedCities");
          const filterdata = [];
          this.props.context.setFilterData(filterdata);
          getDetails(
            AppConfig["entityNames"]["users"],
            userDetails.userid + "/?totalDetails=true"
          ).then((result) => {
            if (result.success) {
              this.userLoginCount(
                result.data.loginCount,
                userDetails.userid,
                result.data.totalVisits
              );
              this.props.context.setUserDetails(result.data);
              localStorage.setItem("userDetails", JSON.stringify(result.data));
              localStorage.setItem("userLogin", JSON.stringify(true));
              localStorage.setItem(
                "userLoginTime",
                JSON.stringify(new Date().getTime())
              );
              if (
                result.data.userProfile === "B" &&
                ((result.data.newdata && !result.data.newdata.serviceAreas) ||
                  (result.data.newdata && !result.data.buyerHomeFeatures) ||
                  (result.data.newdata && !result.data.buyerHomeFeatures) ||
                  (result.data.newdata &&
                    result.data.newdata.serviceAreas &&
                    result.data.newdata.serviceAreas.length === 0) ||
                  (result.data.newdata &&
                    result.data.buyerHomeFeatures &&
                    result.data.buyerHomeFeatures.minPrice === "") ||
                  (result.data.newdata &&
                    result.data.buyerHomeFeatures &&
                    result.data.buyerHomeFeatures.maxPrice === ""))
              ) {
                if (this.props.goTopreviousPage) {
                  window.location.reload();
                } else {
                  this.props.history.push(`/viewProfile/${result.data.id}`);
                }
              } else {
                if (this.props.goTopreviousPage) {
                  window.location.reload();
                } else {
                  if (
                    result.data.userProfile === "B" ||
                    result.data.userProfile === "S"
                  ) {
                    this.props.history.push(`/viewProfile/${result.data.id}`);
                  } else {
                    this.props.history.push("/homes");
                  }
                }
              }
            } else {
              console.log(result.errors);
            }
          });
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      message.error("Invalid Confirm Code");
    }
  };

  handleLoginForm = () => {
    this.props.userHasAuthenticated(true);
  };

  getLoginData = (id) => {
    console.log(id, "llllll");
    getDetails(
      AppConfig["entityNames"]["users"],
      id + "/?totalDetails=true"
    ).then((result) => {
      if (result.success) {
        this.userLoginCount(
          result.data.loginCount,
          id,
          result.data.totalVisits
        );
        this.props.context.setUserDetails(result.data);
        localStorage.setItem("userDetails", JSON.stringify(result.data));
        localStorage.setItem("userLogin", JSON.stringify(true));
        localStorage.setItem(
          "userLoginTime",
          JSON.stringify(new Date().getTime())
        );
        if (
          result.data.userProfile === "B" &&
          ((result.data.newdata && !result.data.newdata.serviceAreas) ||
            (result.data.newdata && !result.data.buyerHomeFeatures) ||
            (result.data.newdata && !result.data.buyerHomeFeatures) ||
            (result.data.newdata &&
              result.data.newdata.serviceAreas &&
              result.data.newdata.serviceAreas.length === 0) ||
            (result.data.newdata &&
              result.data.buyerHomeFeatures &&
              result.data.buyerHomeFeatures.minPrice === "") ||
            (result.data.newdata &&
              result.data.buyerHomeFeatures &&
              result.data.buyerHomeFeatures.maxPrice === ""))
        ) {
          if (this.props.goTopreviousPage) {
            window.location.reload();
          } else {
            this.props.history.push(`/viewProfile/${result.data.id}`);
          }
        } else {
          if (this.props.goTopreviousPage) {
            window.location.reload();
          } else {
            if (
              result.data.userProfile === "B" ||
              result.data.userProfile === "S"
            ) {
              this.props.history.push(`/viewProfile/${result.data.id}`);
            } else {
              this.props.history.push("/homes");
            }
          }
        }
      } else {
        console.log(result.errors);
      }
    });
  };

  loginPopup = () => {
    return (
      <>
        <Row className="register" style={{ padding: "0.5%" }}>
          <Col
            xl={{ span: 18, offset: 5 }}
            lg={{ span: 18, offset: 5 }}
            md={{ span: 18, offset: 5 }}
            xs={{ span: 17, offset: 5 }}
            sm={{ span: 17, offset: 5 }}
          >
            <Button
              type="text"
              className="register-text"
              onClick={this.handleSignupPopup}
              style={{ textAlign: "center" }}
            >
              Don’t have an account? Create one
            </Button>
          </Col>
          {window.location.pathname === "/login" ? (
            <Col
              xl={{ span: 1 }}
              lg={{ span: 1 }}
              md={{ span: 1 }}
              xs={{ span: 1 }}
              sm={{ span: 1 }}
              style={{ textAlign: "left" }}
            >
              <Link to="/">
                <CloseOutlined className="close-btn" />
              </Link>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
            className="mobile-view-display"
          >
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="logo-header-login"
                style={{ marginBottom: "0px" }}
              />
            </Link>
          </Col>
        </Row>
        <Row className="login-height">
          <Col
            xl={{ span: 12, offset: 6 }}
            lg={{ span: 21, offset: 2 }}
            md={{ span: 21, offset: 2 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <div className="login-form-center">
              <h1 className="register-form-center-weight login-margin-top">
                Welcome back
              </h1>
              <Row>
                <Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 21, offset: 2 }}
                  md={{ span: 21, offset: 2 }}
                  sm={{ span: 24, offset: 1 }}
                  xs={{ span: 24, offset: 1 }}
                >
                  <SigninWithFacebook
                    buttonText={"Sign in"}
                    // handleToRedirectPath={this.handleToRedirectPath}
                    getLoginData={this.getLoginData}
                  />
                </Col>
                <Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 21, offset: 2 }}
                  md={{ span: 21, offset: 2 }}
                  sm={{ span: 24, offset: 1 }}
                  xs={{ span: 24, offset: 1 }}
                >
                  <SigninWithGoogle
                    buttonText={"Sign in"}
                    // handleToRedirectPath={this.handleToRedirectPath}
                    getLoginData={this.getLoginData}
                  />
                </Col>
                {/* <Col>
                    <SiginWIthJSDk
                    buttonText={"Sign in"}
                    
                    />
                </Col> */}
              </Row>
            </div>
            <Divider> or </Divider>
            <Form
              name="normal_login"
              className="login-form"
              ref={this.formRef}
              initialValues={{
                remember: true,
              }}
              onFinish={this.onFinish}
            >
              {/* <Form.Item>
                <h1 className="login-form-center register-form-center-weight login-margin-top"></h1>
              </Form.Item> */}

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your e-mail!",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailForm}
                  className="form-input"
                  style={{ textTransform: "lowercase" }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="password"
                  className="form-input"
                />
              </Form.Item>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item>
                    <span
                      style={{
                        color: "#39acda",
                        float: "right",
                        marginTop: "-12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          openLoginPopup: false,
                          openForgotPopup: true,
                        });
                        localStorage.setItem("currentPage", "forgotPage");
                      }}
                    >
                      Forgot password?
                    </span>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="login-form-button"
                >
                  LOGIN
                </Button>
              </Form.Item>
              <h4
                style={{
                  textAlign: "center",
                  color: "#aaa",
                }}
              >
                Version 5.07.10.02
              </h4>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  forgotPopup = () => {
    return (
      <>
        <Row className="register" style={{ padding: "0.5%" }}>
          <Col
            xl={{ span: 18, offset: 5 }}
            lg={{ span: 18, offset: 5 }}
            md={{ span: 18, offset: 5 }}
            xs={{ span: 18, offset: 5 }}
            sm={{ span: 18, offset: 5 }}
          >
            <Button
              type="text"
              className="register-text"
              onClick={() => {
                this.setState({
                  email: "",
                  openLoginPopup: true,
                  openForgotPopup: false,
                  openConfirmpasswordPopup: false,
                });
                localStorage.removeItem("forgotEmail");
                localStorage.removeItem("currentPage");
              }}
              style={{ textAlign: "center" }}
            >
              Already have an account? Login here
            </Button>
          </Col>
          {window.location.pathname === "/login" ? (
            <Col
              xl={{ span: 1 }}
              lg={{ span: 1 }}
              md={{ span: 1 }}
              xs={{ span: 1 }}
              sm={{ span: 1 }}
              style={{ textAlign: "left" }}
            >
              <Link to="/">
                <CloseOutlined className="close-btn" />
              </Link>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
            className="mobile-view-display"
          >
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="logo-header-login"
                style={{ marginBottom: "0px" }}
              />
            </Link>
          </Col>
        </Row>
        <Row className="login-height">
          <Col
            xl={{ span: 12, offset: 6 }}
            lg={{ span: 21, offset: 2 }}
            md={{ span: 21, offset: 2 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Form
              name="normal_login"
              className="login-form"
              ref={this.formRef}
              initialValues={{
                remember: true,
              }}
              onFinish={this.handleForgotSubmit}
            >
              <Form.Item>
                <h1 className="login-form-center register-form-center-weight login-margin-top">
                  Forgot Password
                </h1>
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailForm}
                  className="form-input"
                  style={{ textTransform: "lowercase" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="login-form-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  };
  confirmPasswordPopup = () => {
    return (
      <>
        <Row className="register" style={{ padding: "0.5%" }}>
          <Col
            xl={{ span: 18, offset: 5 }}
            lg={{ span: 18, offset: 5 }}
            md={{ span: 18, offset: 5 }}
            xs={{ span: 18, offset: 5 }}
            sm={{ span: 18, offset: 5 }}
          >
            <Button
              type="text"
              className="register-text"
              onClick={() => {
                this.setState({
                  email: "",
                  openLoginPopup: true,
                  openForgotPopup: false,
                  openConfirmpasswordPopup: false,
                });
                localStorage.removeItem("forgotEmail");
                localStorage.removeItem("currentPage");
              }}
              style={{ textAlign: "center" }}
            >
              Already have an account? Login here
            </Button>
          </Col>
          {window.location.pathname === "/login" ? (
            <Col
              xl={{ span: 1 }}
              lg={{ span: 1 }}
              md={{ span: 1 }}
              xs={{ span: 1 }}
              sm={{ span: 1 }}
              style={{ textAlign: "left" }}
            >
              <Link to="/">
                <CloseOutlined className="close-btn" />
              </Link>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
            className="mobile-view-display"
          >
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="logo-header-login"
                style={{ marginBottom: "0px" }}
              />
            </Link>
          </Col>
        </Row>
        <Row className="login-height">
          <Col
            xl={{ span: 12, offset: 6 }}
            lg={{ span: 21, offset: 2 }}
            md={{ span: 21, offset: 2 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Form
              name="normal_login"
              className="login-form"
              ref={this.formRef}
              initialValues={{
                remember: true,
              }}
              onFinish={this.handleConfirmPasswordSubmit}
            >
              <Form.Item>
                <h1 className="login-form-center register-form-center-weight login-margin-top">
                  Set a New Password
                </h1>
              </Form.Item>
              <Form.Item
                name="email"
                initialValue={this.state.email}
                rules={[
                  {
                    required: true,
                    message: "Please input your Code!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailForm}
                  className="form-input"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                name="confirmationCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your code!",
                  },
                ]}
              >
                <Input
                  placeholder="Confirmation Code"
                  value={this.state.confirmationCode}
                  onChange={(e) => {
                    this.setState({ confirmationCode: e.target.value });
                  }}
                  className="form-input"
                />
              </Form.Item>

              <Form.Item
                name="newpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="New Password"
                  value={this.state.newpassword}
                  onChange={(e) =>
                    this.handleOnChange(
                      "newpassword",
                      e.target.value,
                      "validatePassword"
                    )
                  }
                  className="form-input"
                />
              </Form.Item>
              <Form.Item
                name="confirmnewpassword"
                rules={[
                  {
                    required: false,
                    message: "Please input your Password!",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input.Password
                  placeholder="Confirm New Password"
                  value={this.state.confirmnewpassword}
                  onChange={(e) =>
                    this.handleOnChange(
                      "confirmnewpassword",
                      e.target.value,
                      "validateConfirmPassword"
                    )
                  }
                  className="form-input"
                />
              </Form.Item>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item style={{ marginTop: "-7px", marginBottom: "0px" }}>
                  <Popover content={this.content} title="Password Criteria">
                    <span
                      type="text"
                      style={{
                        color: "#39acda",
                        cursor: "pointer",
                        float: "right",
                      }}
                    >
                      Password Criteria
                    </span>
                  </Popover>
                </Form.Item>
              </Col>
              <span
                className="login-form ant-form-item-explain ant-form-item-explain-error"
                style={{ color: "red" }}
              >
                {this.state.passwordError}
              </span>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="login-form-button"
                  style={{ marginTop: "1vw" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { showAdd } = this.props;
    return (
      <div style={{ overflow: "hidden", backgroundColor: "#eff2f5" }}>
        {showAdd === false ? (
          <Row>
            <Col xs={24}>{this.state.openLoginPopup && this.loginPopup()}</Col>
            <Col xs={24}>
              {this.state.openForgotPopup && this.forgotPopup()}
            </Col>
            <Col xs={24}>
              {" "}
              {this.state.openConfirmpasswordPopup &&
                this.confirmPasswordPopup()}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Login />
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              {this.state.openLoginPopup && this.loginPopup()}
              {this.state.openForgotPopup && this.forgotPopup()}
              {this.state.openConfirmpasswordPopup &&
                this.confirmPasswordPopup()}
              <>
                <Row className="advertisement-margin-login">
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: "0",
                      marginBottom: "-0.7%",
                    }}
                  >
                    <Advertisement
                      otherAds={false}
                      adType="Rectangle"
                      adWhereToShow="Login"
                      adLimit="1"
                      zipCode=""
                      showMatchesPage={true}
                    />
                  </div>
                </Row>
              </>
            </Col>
          </Row>
        )}
        <SignupModal
          enableSignupForm={this.state.showSignupPopup}
          handleonCancel={this.handleonCancel}
        />
        <Modal
          visible={this.state.visible}
          title={
            <p
              className="font-family-style font-medium-size"
              style={{ color: "blue", margin: "0px" }}
            >
              Confirm SignUp
            </p>
          }
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          maskClosable={false}
          className="modal-black-close"
          footer={null}
        >
          <div style={{ padding: "5px 10px" }}>
            <span className="font-family-style text-size padding register-confirm">
              We have sent you a confirmation code to your email. Please enter
              the code below to complete your signup. Thank you.
            </span>
          </div>
          <Form
            className="padding font-family-style text-size"
            onFinish={this.handleOk}
            style={{ padding: "5px 10px" }}
          >
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input the code.",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Please input the code."
                className="form-input"
                maxLength="6"
              />
            </Form.Item>
            <Divider></Divider>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                <span className="align">Submit</span>
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(LoginForm));
